/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
import './cropper-tooltip.scss'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { setAllowTooltipInstruction } from '../../redux/actions/AuthActions'
import { windowCloseTooltipInstr } from '../../services/project/windowCloseTooltipInstr'

const tooltipContent = {
  1: {
    title: 'Social media helpers',
    items: [
      "Select your target social platform, and we'll show you which aspect ratios are optimal for it.",
      "You can select 4 aspect ratios and we'll make them simultaneously",
    ],
  },
  2: {
    title: 'Add another aspect ratio',
    items: [
      "Hit this button to confirm you're happy with the composition of the current aspect ratio.",
      'Then select another social platform or aspect ratio, or hit "Make" if you\'ve finished',
    ],
  },
  3: {
    title: 'Confirm aspect ratio selection',
    items: ['All selected aspect ratios are displayed here'],
  },
  // 4: {
  //   title: 'Automatic presenter tracking',
  //   items: [
  //     'If your human subject is constantly moving have tracking switched on, and we\'ll auto-magically keep them in the centre of the frame. Keep video clips under 90 seconds.',
  //   ],
  // },
  // 4: {
  //   title: 'Composition (alignment/zoom)',
  //   items: [
  //     'Create impactful composition!',
  //     'Experiment with where in the frame your subject should be and how much of the frame they should occupy'
  //   ],
  // },
  // 5: {
  //   title: 'Composition (frame)',
  //   items: [
  //     'These handy visual buttons show you what each aspect ratio looks like.',
  //     'Use these on their own or with manual changes to the composition box.'
  //   ],
  // },
}

const CropperTooltip = ({
  changeCropperInstructionStep,
  cropperInstructionStep,
  isCropperInstructionStepActive,
  closeCropperInstructionStep,
}) => {
  const [offset, setOffset] = useState({ left: 0, top: 0 })
  const [startCrop, setStartCrop] = useState(false)
  const {
    recorder: {
      snapGridPosition: { left, top },
    },
  } = useSelector((state) => state)
  const closeSteps = () => {
    closeCropperInstructionStep()
  }
  useEffect(() => {
    const cropData = document.getElementsByClassName('ratio-action')
    if (cropData && cropData.length > 0) {
      const cropHeight = cropData[0].style.height
      const cropWidth = cropData[0].style.width
      if (cropperInstructionStep === 1) {
        // setStartCrop(true)
        // setOffset({
        //   left: left + Number(cropWidth.slice(0, -2)) / 2 + 30,
        //   top: top - 30,
        // })
      } else if (cropperInstructionStep === 2) {
        // setStartCrop(true)
        // setOffset({
        //   left: left + Number(cropWidth.slice(0, -2)) / 2 + 30,
        //   top: top - Number(cropHeight.slice(0, -2)) / 2 - 30,
        // })
      } else {
        setStartCrop(false)
      }
    }
  }, [left, top, cropperInstructionStep])

  useEffect(() => {
    windowCloseTooltipInstr(closeSteps)
  }, [])

  return (
    <>
      <div
        className={`cropper-tooltip ${
          !isCropperInstructionStepActive
            ? 'hide'
            : `step-${cropperInstructionStep}`
        }`}
        style={
          startCrop &&
          (cropperInstructionStep === 1 || cropperInstructionStep === 2)
            ? offset
            : {}
        }>
        <span className="cropper-tooltip-close" onClick={closeSteps}>
          X
        </span>
        <p className="cropper-tooltip-title">
          {tooltipContent[cropperInstructionStep].title}
        </p>
        {tooltipContent[cropperInstructionStep].items.map((x, i) => {
          return (
            <p key={`tooltip-${i}`} className="cropper-tooltip-content">
              {x}
            </p>
          )
        })}
        <div className="cropper-tooltip-footer">
          <p className="cropper-tooltip-step">
            Step {cropperInstructionStep} of 3
          </p>
          <button
            className="cropper-tooltip-btn"
            onClick={changeCropperInstructionStep}>
            {cropperInstructionStep === 3 ? 'Done!' : 'Next'}
          </button>
        </div>
      </div>
    </>
  )
}

export default CropperTooltip
