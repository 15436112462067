import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RATIOS } from '../../constants/AspectRatios'
import {
  checkSaved,
  clearSaved,
  getShowSize,
  setSnapSize,
  toggleShowSaveTip,
} from '../../methods/recorder/RatioActionMethods'

import * as VideoCropActions from '../../redux/actions/VideoCropActions'
import * as FaceTrackActions from '../../redux/actions/FaceTrackActions'
import * as RecorderActions from '../../redux/actions/RecorderActions'
import * as ProjectEditActions from '../../redux/actions/ProjectEditActions'
import * as SnapGridBoxActions from '../../redux/actions/SnapGridBoxActions'
import {
  SubscriptionManager,
  alertSubscriptionMessage,
  triggerGoogleAnalyticsEvent,
} from '../../utils/Helper'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/modal'
import { Button, Tooltip } from '@chakra-ui/react'
import { EditIcon } from '../cropper/icons/EditIcon'
import { DeleteIcon } from '../cropper/icons/DeleteIcon'
import { InfoIcon } from '../cropper/icons/InfoIcon'
import { CloseIcon } from '../common/icons/CloseIcon'
import { CropperApplyRatiosTrigger } from '../cropper/icons/CropperApplyRatiosTrigger'
import { toast } from 'react-toastify'

class CropperTopButtons extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmDeleteRatio: false,
      ratioToDelete: null,
    }
    this.getShowSize = getShowSize.bind(this) // get replay video show size(video showing size, browser size)
    this.setSnapSize = setSnapSize.bind(this)
    this.checkSaved = checkSaved.bind(this)
    this.clearSaved = clearSaved.bind(this)
    this.toggleShowSaveTip = toggleShowSaveTip.bind(this)

    this.moveRef = props.moveRef
    this.cancelRef = React.createRef()
  }

  wantToDeleteRatio = (ratio) => {
    this.setState({ confirmDeleteRatio: true, ratioToDelete: ratio })
  }

  cancelRatioDeleteModal = () => {
    this.setState({ confirmDeleteRatio: false, ratioToDelete: null })
  }

  addNewAspectRatio() {
    const {
      authUser,
      subscriptions: { user: userSubscription, products },
      savedLayouts,
      selectedRatio,
    } = this.props
    if (
      !SubscriptionManager(authUser, userSubscription, products)?.isProPlan &&
      savedLayouts.filter((x, index) => x && index !== selectedRatio).length >=
        2
    ) {
      return alertSubscriptionMessage(
        'Looks like you’re killing it on your socials. To create up to 4 aspect ratios simultaneously, please upgrade to the Cerebriam Pro package.',
      )
    }
    this.props.selectRatio(this.props.selectedRatio)
    this.props.resetSelectedAspectRatios()
    this.checkSaved()
  }

  isSceneTrackEnabled() {
    const {
      isFaceTrackingEnabled,
      isFaceTrackingEnabledSegmentations,
      segmentedVideos,
      currentVideoId,
    } = this.props
    let isSceneTrackEnabled = !segmentedVideos?.length
      ? isFaceTrackingEnabled
      : false
    if (segmentedVideos?.length) {
      isSceneTrackEnabled = isFaceTrackingEnabledSegmentations.find(
        (x) => x.id === currentVideoId,
      )?.isFaceTrackingEnabled
    }
    return isSceneTrackEnabled
  }

  aiTracking() {
    const {
      authUser,
      subscriptions: { user: userSubscription, products },
    } = this.props
    return toast.warning(
      'We’re upgrading this feature - email us through the Settings menu if you need it sooner!',
    )
    // if (!SubscriptionManager(authUser, userSubscription, products)?.isProPlan) {
    //   return alertSubscriptionMessage(
    //     'Looking to track the person in the frame? No problem, just upgrade to the Cerebriam Pro package.',
    //   )
    // }
    // const userFeatures = this.props.authUser.hasFeatures
    // let isUserFeaturesTrackerEnabled = false
    // if (userFeatures) {
    //   if (userFeatures.tracker) {
    //     isUserFeaturesTrackerEnabled = userFeatures.tracker
    //   }
    // }
    // // if (isUserFeaturesTrackerEnabled) {
    // const { isFaceTrackingEnabled } = this.props
    // triggerGoogleAnalyticsEvent('set_face_tracking', {
    //   value: !this.isSceneTrackEnabled(),
    //   userId: this.props.authUser?.id,
    // })
    // this.props.setFaceTrackingEnabled(
    //   !this.isSceneTrackEnabled(),
    //   this.props.currentVideoId,
    // )
  }

  removeSelectedRatio = () => {
    this.props.removeRatioLayout(
      this.state.ratioToDelete,
      this.props.currentVideoId,
    )
    this.props.removeRatio(this.state.ratioToDelete)
    this.cancelRatioDeleteModal()
  }

  selectRatio(ratio) {
    this.props.clearEditRatio()
    this.handleChangeCurrentLayout(ratio)
    this.props.updateSelectedRatio(ratio)
  }

  handleChangeCurrentLayout(s) {
    this.props.changeLayoutFromDropdown(true)
    this.props.setCurrentLayout(s)
    this.props.setFaceTrackingRatio(s)
    this.props.setSelectedMediaLayout(true, s)
  }

  render() {
    const trackingClassName = this.isSceneTrackEnabled() ? 'on' : 'off'
    if (!this.props.isFaceTrackingPreviewEnabled) {
      return (
        <div className="cropper-top-buttons">
          <div
            className="cropper-top-buttons__content"
            style={{ ...this.props.customStyles }}>
            {!this.props.showOnlyRatios && (
              <div className="flex gap-4 items-center justify-around left-buttons">
                <CloseIcon style={{ zIndex: 10, cursor: 'pointer' }} />
                <InfoIcon
                  onClick={() => this.props.retriggerCropperTooltip()}
                />
                {this.props.segmentedVideos.length > 1 && (
                  <CropperApplyRatiosTrigger
                    resetApplySelectedRatios={
                      this.props.resetApplySelectedRatios
                    }
                    notShownApplySelectedRatiosModal={
                      this.props.notShownApplySelectedRatiosModal
                    }
                  />
                )}

                <button
                  className={`cropper-tracking-${trackingClassName}`}
                  onClick={() => this.aiTracking()}>
                  <svg
                    width="11"
                    height="15"
                    viewBox="0 0 11 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.55336 4.86L3.33336 5.304V7.66667H2.00003V4.36667H2.01003L5.52203 3.088C5.68469 3.026 5.86203 2.99467 6.04336 3.00067C6.4057 3.0097 6.75622 3.1314 7.04619 3.34886C7.33617 3.56631 7.55119 3.8687 7.66136 4.214C7.78536 4.60267 7.89869 4.86533 8.00136 5.00133C8.31157 5.4152 8.71397 5.75107 9.17663 5.98229C9.63929 6.2135 10.1495 6.3337 10.6667 6.33333V7.66667C9.97822 7.66742 9.29815 7.51552 8.67543 7.22191C8.05271 6.9283 7.50282 6.50028 7.06536 5.96867L6.67803 8.16667L8.00003 9.44667V14.3333H6.66669V10.3427L5.30003 9.018L4.66869 11.8833L0.0726929 11.0733L0.304693 9.76L3.58736 10.3387L4.55336 4.86ZM7.00003 2.66667C6.6464 2.66667 6.30727 2.52619 6.05722 2.27614C5.80717 2.02609 5.66669 1.68696 5.66669 1.33333C5.66669 0.979711 5.80717 0.640573 6.05722 0.390524C6.30727 0.140476 6.6464 0 7.00003 0C7.35365 0 7.69279 0.140476 7.94283 0.390524C8.19288 0.640573 8.33336 0.979711 8.33336 1.33333C8.33336 1.68696 8.19288 2.02609 7.94283 2.27614C7.69279 2.52619 7.35365 2.66667 7.00003 2.66667Z"
                      fill={this.isSceneTrackEnabled() ? 'black' : 'white'}
                    />
                  </svg>
                </button>
              </div>
            )}

            <div className="cropper-selected-ratios">
              {RATIOS.map((ratio, index) => {
                const aspect = this.props.aspectSize[index]
                if (aspect.height && aspect.width) {
                  return (
                    <div className={`ratio ${ratio.name}`} key={index}>
                      <span>{ratio.ratio}</span>
                      {/* <span className="remove-icon">X</span> */}

                      <div className="ratio-action-btns">
                        <Tooltip label="Edit ratio">
                          <div
                            className="edit"
                            onClick={() => {
                              this.selectRatio(ratio.id)
                              this.props.wantToEditRatio(ratio.id)
                            }}>
                            <EditIcon />
                          </div>
                        </Tooltip>
                        <Tooltip label="Delete ratio">
                          <div
                            className="delete"
                            onClick={() => this.wantToDeleteRatio(ratio.id)}>
                            <DeleteIcon />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  )
                }
                return
              })}
            </div>

            {!this.props.showOnlyRatios && (
              <div className="buttons" style={{ zIndex: 50 }}>
                <button
                  disabled={this.props.isFaceTrackingPreviewEnabled}
                  className="complete-btn"
                  onClick={this.props.onUploadRecordVideo}>
                  Make
                </button>
                <button
                  className="add-ratio-btn"
                  disabled={this.props.isFaceTrackingPreviewEnabled}
                  onClick={() => this.addNewAspectRatio()}>
                  +
                </button>
              </div>
            )}
          </div>
          <AlertDialog
            isOpen={this.state.confirmDeleteRatio}
            leastDestructiveRef={this.cancelRef}
            onClose={this.cancelRatioDeleteModal}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete scene ratio
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter style={{ backgroundColor: 'white' }}>
                  <Button
                    ref={this.cancelRef}
                    onClick={this.cancelRatioDeleteModal}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme="red"
                    ml={3}
                    onClick={this.removeSelectedRatio}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </div>
      )
    }
    return null
  }
}

const mapStateToProps = (state) => ({
  isFaceTrackingEnabled: state.faceTrack.isFaceTrackingEnabled,
  isFaceTrackingEnabledSegmentations:
    state.faceTrack.isFaceTrackingEnabledSegmentations,
  media_layout: state.recorder.media_layout,
  resolution: state.recorder.resolution,
  cameraDimension: state.recorder.cameraDimension,
  selectedLayouts: state.recorder.selectLayout,
  savedLayouts: state.recorder.saveLayout,
  isCropped: state.recorder.isCropped,
  aspectSize: state.recorder.aspectSize,
  project: state.editor.project,
  defaultLayout: state.projectEdit.defaultLayout,
  currentLayout: state.videoCrop.currentLayout,
  zoomAbleState: state.recorder.zoomAbleState,
  shoot_value: state.recorder.shoot_value,
  authUser: state.auth.user,
  subscriptions: state.subscriptions,
})

const mapDispatchProps = (dispatch) => ({
  setFaceTrackingEnabled: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingEnabled(...args)),
  setMediaStreamSize: (...args) =>
    dispatch(SnapGridBoxActions.setMediaStreamSize(...args)),
  setMediaOriginSize: (...args) =>
    dispatch(SnapGridBoxActions.setMediaOriginSize(...args)),
  setSelectedMediaLayout: (...args) =>
    dispatch(RecorderActions.setSelectedMediaLayout(...args)),
  setCurrentLayout: (...args) =>
    dispatch(VideoCropActions.setCurrentLayout(...args)),
  setFaceTrackingRatio: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingRatio(...args)),
  setDefaultLayout: (...args) =>
    dispatch(ProjectEditActions.setDefaultLayout(...args)),
  setSaveRatioLayout: (...args) =>
    dispatch(RecorderActions.setSaveRatioLayout(...args)),
  removeRatioLayout: (...args) =>
    dispatch(RecorderActions.removeRatioLayout(...args)),
  setReviewScreenSize: (...args) =>
    dispatch(SnapGridBoxActions.setReviewScreenSize(...args)),
  setVideoAspectSize: (...args) =>
    dispatch(SnapGridBoxActions.setVideoAspectSize(...args)),
})

export default connect(mapStateToProps, mapDispatchProps)(CropperTopButtons)
