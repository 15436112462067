import { toast } from 'react-toastify'
import { useState } from 'react'
import { Switch } from '@chakra-ui/react'
import thumb_tick from '../../assets/images/thumb_tick.png'
import instance from '../../utils/Axios'
import { PaymentConstants } from '../../constants/Payments'

const ProductsFeatures = ({
  activeProduct,
  changeActiveProduct,
  paymentTypeText,
  subscriptionType,
  referredByAffiliate,
  user,
  show,
  pageOn,
  regionProductPro,
  regionProductStudio,
  changeActiveCycle,
}) => {
  const [loading, setLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(referredByAffiliate)
  const [billDuration, setBillDuration] = useState(
    referredByAffiliate
      ? PaymentConstants.subscriptionDuration.annually
      : PaymentConstants.subscriptionDuration.monthly,
  )

  const percent_off = user?.firstPurchaseDiscount?.percent_off || 0
  const amount_off = user?.firstPurchaseDiscount?.amount_off || 0

  const getDiscountedAmount = (actualAmount) => {
    const curr = actualAmount[0]
    const amount = actualAmount.slice(1)
    if (percent_off) {
      return curr + (amount - (amount * percent_off) / 100)
    }
    if (amount_off) {
      return curr + (amount - amount_off / 100)
    }
    return actualAmount
  }

  const handleChange = (event) => {
    const checkVal = event.target.checked
    const duration = checkVal
      ? PaymentConstants.subscriptionDuration.annually
      : PaymentConstants.subscriptionDuration.monthly
    setBillDuration(duration)
    setIsChecked(event.target.checked)
    changeActiveCycle &&
      changeActiveCycle(
        duration === PaymentConstants.subscriptionDuration.annually ? 1 : 2,
      )
  }

  const paySubscription = async (price, pkg) => {
    setLoading(true)
    try {
      const response = await instance.post('/subscriptions/pay', {
        price,
        package: pkg || billDuration,
      })

      window.location.href = response.data.url
    } catch (err) {
      toast.error('Cannot get payment link')
    } finally {
      setLoading(false)
    }
  }

  const isDiscountApplicable = percent_off > 0 || amount_off > 0

  return (
    <div
      className="cerebriam-form"
      style={{ display: show ? 'block' : 'none' }}>
      {regionProductPro && regionProductStudio && (
        <>
          <table className="products-table">
            <thead>
              {!referredByAffiliate && (
                <tr>
                  <th style={{ border: 0 }}></th>
                  <th colSpan={2} style={{ border: 0 }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <p
                        style={{
                          color:
                            PaymentConstants.subscriptionDuration.monthly ===
                            billDuration
                              ? 'grey'
                              : 'rgb(163, 163, 163)',
                        }}>
                        Bill Monthly
                      </p>
                      <Switch onChange={handleChange} isChecked={isChecked} />
                      <div style={{ textAlign: 'left' }}>
                        <p
                          style={{
                            color:
                              PaymentConstants.subscriptionDuration.annually ===
                              billDuration
                                ? '#02807f'
                                : 'rgb(163, 163, 163)',
                          }}>
                          Bill Yearly
                        </p>
                        <p
                          style={{
                            fontSize: 12,
                            color:
                              PaymentConstants.subscriptionDuration.annually ===
                              billDuration
                                ? '#02807f'
                                : 'rgb(163, 163, 163)',
                          }}>
                          Save up to 20%
                        </p>
                      </div>
                    </div>
                  </th>
                </tr>
              )}
              <br />
              <br />
              <tr className="first-row">
                <th className="first-column"></th>
                <th>
                  <h3>
                    <label className={`${activeProduct === 1 ? 'active' : ''}`}>
                      {paymentTypeText.productOne}
                    </label>
                  </h3>
                  <p>Good for vloggers & newbies in video production</p>
                  <h4>
                    {getDiscountedAmount(
                      regionProductStudio[`price_${billDuration}`],
                    )}{' '}
                    {referredByAffiliate && isDiscountApplicable && (
                      <span
                        style={{
                          color: 'rgb(171 171 171)',
                          fontSize: 14,
                          textDecoration: 'line-through',
                        }}>
                        {regionProductStudio[`price_${billDuration}`]}
                      </span>
                    )}
                  </h4>
                  <p>
                    Billed {regionProductStudio[`price_${billDuration}`]} per
                    user {billDuration}
                  </p>

                  {pageOn === 'pricing' && (
                    <button
                      className="pricing-subscribe-btn"
                      disabled={loading}
                      onClick={() => changeActiveProduct(1)}>
                      Free 14 day trial
                    </button>
                  )}
                </th>
                <th>
                  <h3>
                    <label className={`${activeProduct === 2 ? 'active' : ''}`}>
                      {paymentTypeText.productTwo}
                    </label>
                  </h3>
                  <p>Essential for those raising their game on social</p>
                  <h4>
                    {getDiscountedAmount(
                      regionProductPro[`price_${billDuration}`],
                    )}{' '}
                    {referredByAffiliate && isDiscountApplicable && (
                      <span
                        style={{
                          color: 'rgb(171 171 171)',
                          fontSize: 14,
                          textDecoration: 'line-through',
                        }}>
                        {regionProductPro[`price_${billDuration}`]}
                      </span>
                    )}
                  </h4>
                  <p>
                    Billed {regionProductPro[`price_${billDuration}`]} per user{' '}
                    {billDuration}
                  </p>
                  {pageOn === 'pricing' && (
                    <button
                      className="pricing-subscribe-btn"
                      disabled={loading}
                      onClick={() => changeActiveProduct(2)}>
                      Free 14 day trial
                    </button>
                  )}
                </th>
                <th className="last-column">
                  <h3>
                    <label className={`${activeProduct === 2 ? 'active' : ''}`}>
                      {paymentTypeText.productThree}
                    </label>
                  </h3>
                  <p>Streamlined integration</p>
                  <h4>Contact</h4>
                  <p>
                    Integrate Cerebriam Pro in to your organisation’s workflow
                  </p>

                  {pageOn === 'pricing' && (
                    <a href="mailto:nageela@cerebriam.co.uk">
                      <button
                        className="pricing-subscribe-btn"
                        style={{}}
                        disabled={loading}>
                        Email Us
                      </button>
                    </a>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="first-column">1-2 aspect ratios</td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td className="last-column">
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr>
              <tr>
                <td className="first-column">Online editor</td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td className="last-column">
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr>
              <tr>
                <td className="first-column">Add music</td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td className="last-column">
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr>
              <tr>
                <td className="first-column">Composition tools</td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td className="last-column">
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr>
              <tr>
                <td className="first-column">
                  Optimise video for target socials
                </td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td className="last-column">
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr>
              <tr>
                <td className="first-column">Web and tablet (iOS & Android)</td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td className="last-column">
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr>
              <tr>
                <td className="first-column">Record video</td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td className="last-column">
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr>
              <tr>
                <td className="first-column">Record voiceover</td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td className="last-column">
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr>
              <tr>
                <td className="first-column">No watermark</td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td className="last-column">
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr>
              <tr>
                <td className="first-column">Auto scene segmentation</td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td className="last-column">
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr>
              <tr>
                <td className="first-column">Import YouTube videos</td>
                <td>
                  {' '}
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td>
                  {' '}
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td className="last-column">
                  {' '}
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr>
              <tr>
                <td className="first-column">1-4 aspect ratios</td>
                <td></td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td className="last-column">
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr>
              <tr>
                <td className="first-column">Edit music &amp; sound</td>
                <td></td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td className="last-column">
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr>
              {/* <tr className="last-row">
                <td className="first-column">AI enabled presenter tracking</td>
                <td></td>
                <td>
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
                <td className="last-column">
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr> */}
              <tr className="last-row">
                <td className="first-column">Streamlined solutions</td>
                <td></td>
                <td></td>
                <td className="last-column">
                  <img
                    className="tick-icon"
                    src={thumb_tick}
                    alt="cerebriam-tick-icon"
                  />
                </td>
              </tr>
              {pageOn !== 'pricing' && (
                <tr className="last-row">
                  <td className="first-column"></td>
                  <td>
                    <button
                      disabled={
                        loading ||
                        subscriptionType ===
                          PaymentConstants.subscriptionProduct.studio
                      }
                      className="pay-button"
                      onClick={() => {
                        paySubscription(
                          regionProductStudio[`stripe_price_${billDuration}`],
                        )
                      }}>
                      Subscribe
                    </button>
                  </td>
                  <td>
                    <button
                      className="pay-button"
                      disabled={loading}
                      onClick={() => {
                        paySubscription(
                          regionProductPro[`stripe_price_${billDuration}`],
                        )
                      }}>
                      {subscriptionType ===
                      PaymentConstants.subscriptionProduct.studio
                        ? 'Upgrade'
                        : 'Subscribe'}
                    </button>
                  </td>
                  <td className="last-column">
                    <a href="mailto:nageela@cerebriam.co.uk">
                      <button
                        className="pay-button"
                        style={{}}
                        disabled={loading}>
                        Email Us
                      </button>
                    </a>
                  </td>
                </tr>
              )}
              {!referredByAffiliate && pageOn !== 'pricing' && (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <tr className="last-row">
                    <td className="first-column"></td>
                    <td>
                      <div
                        style={{
                          textAlign: 'center',
                          marginTop: 10,
                          marginBottom: 10,
                        }}>
                        <p
                          style={{
                            maxWidth: 150,
                            textAlign: 'center',
                            margin: '0 auto',
                            color: '#000',
                          }}>
                          If you're just starting out and don't want to commit
                        </p>
                      </div>
                      <button
                        disabled={
                          loading ||
                          subscriptionType ===
                            PaymentConstants.subscriptionProduct.studio
                        }
                        className="pay-button"
                        onClick={() => {
                          paySubscription(
                            regionProductStudio['stripe_price_pay_as_you_go'],
                            PaymentConstants.subscriptionDuration.pay_as_you_go,
                          )
                        }}>
                        Pay {regionProductStudio?.price_pay_as_you_go}
                      </button>
                    </td>
                    <td className="last-column">
                      <div
                        style={{
                          textAlign: 'center',
                          marginTop: 10,
                          marginBottom: 10,
                        }}>
                        <p
                          style={{
                            maxWidth: 150,
                            textAlign: 'center',
                            margin: '0 auto',
                            color: '#000',
                          }}>
                          If you have advanced work over a short period
                        </p>
                      </div>
                      <button
                        className="pay-button"
                        disabled={loading}
                        onClick={() => {
                          paySubscription(
                            regionProductPro['stripe_price_pay_as_you_go'],
                            PaymentConstants.subscriptionDuration.pay_as_you_go,
                          )
                        }}>
                        Pay {regionProductPro?.price_pay_as_you_go}
                      </button>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </>
      )}

      <br />
      <br />
      <div className="scenes">
        <div className="item"></div>
        <div className="item">
          <img
            src="https://web.cerebriam.com/wp-content/uploads/2022/04/Cerebriam_Studio_2AspectRatios.jpg"
            alt="img"
          />
        </div>
        <div className="item">
          <img
            src="https://web.cerebriam.com/wp-content/uploads/2022/04/Cerebriam_Pro_4AspectRatios.png"
            alt="img"
          />
        </div>
        <div className="item">
          <img
            src="https://web.cerebriam.com/wp-content/uploads/2022/04/enterprise.jpg"
            alt="img"
          />
        </div>
      </div>
      <br />
      <br />

      <p style={{ color: '#7a7a7a', fontSize: 14, fontWeight: 600 }}>
        Loved by entertainers and creators all over the world
      </p>
      <br />
      <br />

      <div className="brands">
        <div>
          <img
            src="https://web.cerebriam.com/wp-content/uploads/2022/04/salamine2.png"
            alt="brand"
          />
        </div>
        <div>
          <img
            src="https://web.cerebriam.com/wp-content/uploads/2022/04/future-workshops.jpg"
            alt="brand"
          />
        </div>
        <div>
          <img
            src="https://web.cerebriam.com/wp-content/uploads/2022/04/download-1.png"
            alt="brand"
          />
        </div>
        <div>
          <img
            src="https://web.cerebriam.com/wp-content/uploads/2022/04/salamine2.png"
            alt="brand"
          />
        </div>
        <div>
          <img
            src="https://web.cerebriam.com/wp-content/uploads/2022/04/etiq.png"
            alt="brand"
          />
        </div>
      </div>

      {/* {!isAuthenticated && !trialRegistered && (
        <div className="bottom-button previous">
          <button
            onClick={() => setIsUserPaying(!isUserPaying)}
            className="cerebriam-btn">
            {isUserPaying ? 'Free Trial' : 'I"m impressed! I want to pay now'}
          </button>
        </div>
      )} */}
    </div>
  )
}

export default ProductsFeatures
